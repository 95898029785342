import React, { useEffect, useState, useContext, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "../../../utils/axios";
import { formatProjectData } from "../../../utils/projectUtilities";

import SnackbarContext from "../../../contexts/SnackbarContext";
import useDialog from "../../../hooks/useDialog";
import Admin from "../../../components/guards/AdminGuard";
import Customer from "../../../components/guards/CustomerGuard";
import Contractor from "../../../components/guards/ContractorGuard";

import ProjectInfo from "./ProjectInfo";
import PoolInfo from "./PoolInfo";
import ContractorInfo from "./ContractorInfo";
import ProgressCard from "./ProgressCard";
import ToDoInfo from "./ToDoInfo";
import DrawInfo from "./DrawInfo";
import ProgressPictures from "./ProgressPictures";
import CostInfo from "./CostInfo";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  CircularProgress as MuiCircularProgress,
  Link,
  Grid,
  Typography as MuiTypography,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Details = () => {
  const { project_id } = useParams();
  const [project, setProject] = useState(null);
  const { showDialog, hideDialog } = useDialog();
  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleDeleteProject = useCallback(() => {
    showDialog({
      title: "Delete Contractor",
      content: (
        <>
          This will permanently remove{" "}
          <Typography component="span" fontWeight="bold" fontSize={16}>
            {project.name}
          </Typography>{" "}
          . Are you sure you would like to continue?
        </>
      ),
      onConfirm: async () => {
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_SERVER_HOST}/api/projects/${project_id}`
          );
          console.log("Project Deleted", response.data);
          showSnackbar("Project Deleted", "success");
          navigate("/");
        } catch (error) {
          console.error("Error deleting project:", error);
          showSnackbar("Failed to Delete Project", "error");
        }
        hideDialog();
      },
      onCancel: () => {
        console.log("Canceled");
        hideDialog();
      },
      onConfirmText: "Delete",
    });
  }, [showDialog, project, hideDialog, project_id, showSnackbar, navigate]);

  const fetchProject = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_HOST}/api/projects/${project_id}`
      );
      const formattedProject = formatProjectData(response.data);
      setProject(formattedProject);
    } catch (error) {
      console.error("Error fetching project:", error);
      showSnackbar("Failed to Load Project", "error");
    }
  }, [project_id, showSnackbar]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject, project_id]);

  if (!project) {
    return (
      <Card mb={6}>
        <CardContent>
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
          <Typography variant="h6" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Project Details" />
      <Contractor>
        <Typography variant="h3" gutterBottom display="inline">
          {project.name}
        </Typography>
      </Contractor>

      <Admin>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/projects/">
            Projects
          </Link>
          <Typography>{project.name}</Typography>
        </Breadcrumbs>
      </Admin>
      <Contractor>
        <Divider my={6} />
      </Contractor>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <ProjectInfo
                project={project}
                handleDeleteProject={handleDeleteProject}
              />
            </Grid>
            <Grid item xs={12}>
              <ProgressCard project={project} fetchProject={fetchProject} />
            </Grid>
            <Grid item xs={12}>
              <Admin>
                <ContractorInfo project={project} />
              </Admin>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <PoolInfo project={project} />
            </Grid>
            <Customer>
              <Grid item xs={12} lg={4}>
                <DrawInfo project={project} fetchProject={fetchProject} />
              </Grid>
            </Customer>
            <Admin>
              <Grid item xs={12} lg={4}>
                <CostInfo project={project} fetchProject={fetchProject} />
              </Grid>
            </Admin>
            <Admin>
              <Grid item xs={12} lg={4}>
                <ToDoInfo project={project} />
              </Grid>
            </Admin>
            <Grid item xs={12} lg={8}>
              <ProgressPictures project={project} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Details;
