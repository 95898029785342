import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import {
  CardContent,
  Typography,
  Grid,
  Card,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

function ToDoInfo({ project }) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom mb={5}>
          To-Do
        </Typography>
        {project.todos &&
          project.todos.map((todo) => (
            <React.Fragment key={todo.todo_id}>
              <NavLink
                to={`/projects/todo/${todo.todo_id}`}
                style={{ textDecoration: "none" }}
                activestyle={{}}
              >
                <Card mb={1}>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <Grid item xs={6} lg={6} fontWeight="fontWeightMedium">
                        {todo.title}
                      </Grid>
                      <Grid item xs={6} lg={6} fontWeight="fontWeightMedium">
                        ({todo.data.length}) Tasks Left
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </NavLink>
              <Divider mb={2} />
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

export default React.memo(ToDoInfo);
