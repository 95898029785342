import React from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  Typography,
  Grid,
  Card,
  Divider as MuiDivider,
  Chip as MuiChip,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Chip = styled(MuiChip)(spacing);

function ContractorInfo({ project }) {
  return (
    <Card mb={6} mt={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom mb={2}>
          Contractor Info
        </Typography>

        {project.contractors &&
          project.contractors.map((contractor) => (
            <React.Fragment key={contractor.contractor_id}>
              <Card
                mb={6}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                }}
              >
                <CardContent>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {contractor.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="lightblue"
                    >
                      {contractor.phone_number}
                    </Typography>
                  </div>
                  <Divider mb={2} mt={2} />
                  <Grid container spacing={1} mt={2}>
                    {contractor.types &&
                      contractor.types.map((type, index) => (
                        <Grid item key={index}>
                          <Chip label={type} color="secondary" />
                        </Grid>
                      ))}
                  </Grid>
                </CardContent>
              </Card>
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

export default React.memo(ContractorInfo);
