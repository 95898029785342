import { createContext, useState } from "react";
import { Slide } from "@mui/material";

const SnackbarContext = createContext(null);

export const SnackbarProvider = ({ children }) => {
  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    vertical: "top",
    horizontal: "center",
    transition: null,
  });

  const showSnackbar = (
    message,
    severity = "success",
    vertical = "top",
    horizontal = "center",
    transition = TransitionDown
  ) => {
    setSnackbar({
      open: true,
      message,
      vertical,
      horizontal,
      severity,
      transition,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        showSnackbar,
        closeSnackbar,
        TransitionDown,
        TransitionLeft,
        TransitionRight,
        TransitionUp,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
