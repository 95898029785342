import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Card,
  CardContent,
  ButtonBase,
  Box,
} from "@mui/material";
import { Dropbox } from "dropbox";
import PerfectScrollbar from "perfect-scrollbar";
import { decrypt, encrypt } from "../utils/projectUtilities";
import { dropboxConfig } from "../config";

const DropboxPickerContext = createContext(null);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//////TEMP
// eslint-disable-next-line no-unused-vars
const fetchRefreshToken = async (code) => {
  const appKey = dropboxConfig.appKey;
  const appSecret = dropboxConfig.appSecret;

  const tokenURL = "https://api.dropbox.com/oauth2/token";
  const base64authorization = Buffer.from(`${appKey}:${appSecret}`).toString(
    "base64"
  );

  const headers = new Headers({
    Authorization: `Basic ${base64authorization}`,
    "Content-Type": "application/x-www-form-urlencoded",
  });

  const body = new URLSearchParams();
  body.append("grant_type", "authorization_code");
  body.append("code", code);

  try {
    const response = await fetch(tokenURL, {
      method: "POST",
      headers: headers,
      body: body,
    });

    if (!response.ok) {
      const errorData = await response.json();

      throw new Error(
        `Failed to fetch refresh token (status: ${response.status}, statusText: ${response.statusText}, error: ${errorData.error}, error_description: ${errorData.error_description})`
      );
    }

    const data = await response.json();

    console.log("DATA -", data);
    return data.refresh_token;
  } catch (error) {
    console.error("Error fetching refresh token:", error);
  }
};

const GetDecryptedAccessToken = async () => {
  const token = sessionStorage.getItem("encryptedAccessToken");
  if (token) {
    const decryptedAccessToken = await decrypt(token);

    return decryptedAccessToken;
  }
  return null;
};

const fetchAccessTokenWithRefreshToken = async (refreshToken) => {
  const appKey = dropboxConfig.appKey;
  const appSecret = dropboxConfig.appSecret;

  const tokenURL = "https://api.dropbox.com/oauth2/token";
  const base64authorization = Buffer.from(`${appKey}:${appSecret}`).toString(
    "base64"
  );

  const headers = new Headers({
    Authorization: `Basic ${base64authorization}`,
    "Content-Type": "application/x-www-form-urlencoded",
  });

  const body = new URLSearchParams();
  body.append("grant_type", "refresh_token");
  body.append("refresh_token", refreshToken);

  try {
    const response = await fetch(tokenURL, {
      method: "POST",
      headers: headers,
      body: body,
    });

    if (!response.ok) {
      const errorData = await response.json();

      throw new Error(
        `Failed to fetch refresh token (status: ${response.status}, statusText: ${response.statusText}, error: ${errorData.error}, error_description: ${errorData.error_description})`
      );
    }

    const data = await response.json();

    return data.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
  }
};

const DropboxPickerProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [pickerOptions, setPickerOptions] = useState({
    type: "folder",
    initialPath: "/",
    onPick: null,
  });
  const [items, setItems] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const containerRef = useRef(null);
  const [scrollbar, setScrollbar] = useState(null);
  const [accessToken, setAccessToken] = useState(GetDecryptedAccessToken());

  const dbx = useMemo(() => {
    return new Dropbox({
      accessToken: accessToken,
    });
  }, [accessToken]);

  useEffect(() => {
    const fetchNewAccessToken = async () => {
      const refreshToken = dropboxConfig.refreshToken;
      try {
        const newToken = await fetchAccessTokenWithRefreshToken(refreshToken);
        if (newToken) {
          const encryptedToken = encrypt(newToken);
          sessionStorage.setItem("encryptedAccessToken", encryptedToken);
          setAccessToken(newToken);
          dbx.accessToken = newToken;
        } else {
          throw new Error("Unable to fetch new access token");
        }
      } catch (error) {
        console.error("Error fetching new access token:", error);
      }
    };

    const checkAccessToken = async () => {
      try {
        await dbx.usersGetCurrentAccount();
      } catch (error) {
        if (error.status === 401 || error.status === 400) {
          fetchNewAccessToken();
        } else {
          console.error("Error checking access token validity:", error);
        }
      }
    };

    checkAccessToken();
  }, [dbx]);

  useEffect(() => {
    if (containerRef.current && !scrollbar) {
      setScrollbar(new PerfectScrollbar(containerRef.current));
    }
    return () => {
      if (scrollbar) {
        scrollbar.destroy();
      }
    };
  }, [containerRef, scrollbar]);

  const showPicker = async (options) => {
    setPickerOptions(options);
    try {
      const response = await dbx.filesListFolder({ path: options.initialPath });
      const filteredEntries = response.result.entries.filter((entry) => {
        if (options.type === "folder" && entry[".tag"] === "folder") {
          return true;
        } else if (
          options.type === "file" &&
          entry[".tag"] === "file" &&
          options.fileExtensions &&
          options.fileExtensions.length > 0
        ) {
          const fileExtension = entry.name.split(".").pop().toLowerCase();
          return options.fileExtensions.includes(fileExtension);
        }
        return false;
      });
      setItems(filteredEntries);
    } catch (error) {
      console.error("Error listing Dropbox items:", error);
    }
    setOpen(true);
  };

  const hidePicker = () => {
    setOpen(false);
  };

  const handleItemClick = async (item) => {
    try {
      const response = await dbx.sharingCreateSharedLinkWithSettings({
        path: item.path_lower,
        settings: { requested_visibility: "public" },
      });
      const link = response.result.url;
      pickerOptions.onPick(link);
      hidePicker();
    } catch (error) {
      if (error.status === 409) {
        try {
          const existingLinksResponse = await dbx.sharingListSharedLinks({
            path: item.path_lower,
          });
          const existingLink = existingLinksResponse.result.links[0].url;
          pickerOptions.onPick(existingLink);
          hidePicker();
        } catch (innerError) {
          console.error("Error getting existing shared link:", innerError);
        }
      } else {
        console.error("Error creating shared link:", error);
      }
    }
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <DropboxPickerContext.Provider value={{ showPicker, hidePicker }}>
      {children}
      <Dialog open={open} onClose={hidePicker} TransitionComponent={Transition}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Select a {pickerOptions.type}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "400px" }}>
          <TextField
            label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Card>
            <CardContent>
              <List sx={{ maxHeight: "300px", overflow: "auto" }}>
                {filteredItems.map((item) => (
                  <ListItem key={item.id}>
                    <ButtonBase
                      style={{ width: "100%" }}
                      onClick={() => handleItemClick(item)}
                    >
                      <Box
                        component="div"
                        width="100%"
                        sx={{
                          backgroundColor: "action.hover",
                          borderRadius: 1,
                          p: 1,
                          "&:hover": {
                            backgroundColor: "action.selected",
                          },
                        }}
                      >
                        <ListItemText primary={item.name} />
                      </Box>
                    </ButtonBase>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </DropboxPickerContext.Provider>
  );
};

export { DropboxPickerContext, DropboxPickerProvider };
