// navItems.js
import axios from "../../utils/axios";
import { Pool } from "@mui/icons-material";

import {
  Calendar,
  CheckSquare,
  CreditCard,
  Users,
  DollarSign,
} from "react-feather";

const fetchInfo = async (type) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}${type}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    return [];
  }
};

const projectSection = [
  {
    href: "/projects/",
    icon: Pool,
    title: "Projects",
    badgeType: "projects",
  },
  {
    href: "/projects/todo",
    icon: CheckSquare,
    title: "To-Do",
    badgeType: "todo",
  },
  {
    href: "/draws",
    icon: DollarSign,
    title: "Draws",
    badgeType: "draws",
  },
  {
    href: "/invoices",
    icon: CreditCard,
    title: "Invoices",
    badgeType: "invoice",
  },
  {
    href: "/calendar",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/contractors",
    icon: Users,
    title: "Contractors",
  },
];

const navConfig = [
  {
    //title: "Projects",
    pages: projectSection,
  },
];

// Return all items if user is admin. If not they hide the items from contractor since entire sidebar is hidden from customer
const createNavItems = (badgeCounts, userType) => {
  const filteredProjectSection = projectSection.filter((item) => {
    if (userType === parseInt(process.env.REACT_APP_ADMIN_KEY)) {
      return true;
    }
    // Exclude the items with "Invoices", "Calendar", and "Draws" titles for non-admin users
    return (
      item.title !== "Invoices" &&
      item.title !== "Calendar" &&
      item.title !== "Draws"
    );
  });

  return navConfig.map((nav) => ({
    ...nav,
    pages: filteredProjectSection.map((page) => ({
      ...page,
      badge: badgeCounts[page.badgeType],
    })),
  }));
};

export { fetchInfo, createNavItems };
