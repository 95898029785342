export const formatCurrency = (amount) => {
  const toFloat = parseFloat(amount);
  const formattedAmount = toFloat.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedAmount;
};
