import { useContext } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { Snackbar, Alert } from "@mui/material";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";

import SnackbarContext from "./contexts/SnackbarContext";
import { DialogProvider } from "./contexts/DialogContext";
import { DropboxPickerProvider } from "./contexts/DropboxPickerContext";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { snackbar, closeSnackbar } = useContext(SnackbarContext);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Artistic Pools PM"
          defaultTitle="Artistic Pools Project Manager"
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              <DialogProvider>
                <DropboxPickerProvider>
                  {content}{" "}
                  <Snackbar
                    open={snackbar.open}
                    autoHideDuration={4000}
                    onClose={closeSnackbar}
                    message={snackbar.message}
                    anchorOrigin={{
                      vertical: snackbar.vertical,
                      horizontal: snackbar.horizontal,
                    }}
                    TransitionComponent={snackbar.transition}
                    severity={snackbar.severity}
                  >
                    <Alert onClose={closeSnackbar} severity={snackbar.severity}>
                      {snackbar.message}
                    </Alert>
                  </Snackbar>
                </DropboxPickerProvider>
              </DialogProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
