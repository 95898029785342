import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { getFinancialInformation } from "../../../utils/projectUtilities";
import {
  CardContent,
  Typography,
  Button as MuiButton,
  Grid,
  Card,
  Divider as MuiDivider,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment";
import Admin from "../../../components/guards/AdminGuard";
import Customer from "../../../components/guards/CustomerGuard";
import Contractor from "../../../components/guards/ContractorGuard";

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

const ColoredText = styled(Typography)(({ theme, color }) => ({
  color: theme.palette[color].main,
}));

function GetProjectLength(project) {
  if (!project || !project.start_date) {
    return "No start date available";
  }

  const startDate = moment(project.start_date);
  const equipDate = project.equipment_install_date
    ? moment(project.equipment_install_date)
    : moment(); // Use current date if equipment_install_date is not available

  const diffInDays = equipDate.diff(startDate, "days");
  const weeks = Math.floor(diffInDays / 7);
  const days = diffInDays % 7;

  const color = project.equipment_install_date ? "success" : "primary";

  return (
    <ColoredText color={color}>{`${weeks} weeks ${days} days`}</ColoredText>
  );
}

function removeStateAndCountry(address) {
  // Split the address string by commas
  const parts = address.split(",");

  // If the address has at least 3 parts (street, city, state, country)
  if (parts.length >= 3) {
    // Remove the last two parts (state and country)
    parts.pop();
    parts.pop();
  }

  // Join the remaining parts back together with commas
  const shortenedAddress = parts.join(",").trim();
  return shortenedAddress;
}

function handleUrlClick(path) {
  if (path) {
    window.open(path, "_blank");
  } else {
    console.log("URL is not available.");
  }
}

function ProjectInfo({ project, handleDeleteProject }) {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/projects/Edit/${project.project_id}`);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom mb={5}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item fontWeight="fontWeightMedium">
              Project Info
            </Grid>
            <Admin>
              <Grid item fontWeight="fontWeightMedium">
                <Button
                  mr={2}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDeleteProject()}
                >
                  Delete
                </Button>
              </Grid>
            </Admin>
          </Grid>
        </Typography>

        <Contractor>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Grid item fontWeight="fontWeightMedium">
              Customer{" "}
            </Grid>
            <Grid item fontWeight="fontWeightLight">
              {project.name}
            </Grid>
          </Grid>
          <Divider mb={2} />
        </Contractor>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            Token{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight" color="InactiveCaptionText">
            {project.token.token}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            Address{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight">
            <Link
              onClick={() =>
                handleUrlClick(
                  "http://maps.apple.com/?address=" +
                    removeStateAndCountry(project.address)
                )
              }
            >
              {removeStateAndCountry(project.address)}
            </Link>
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            Gate Code{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight">
            {project.gate_code || "N/A"}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            OKIE number{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight">
            {project.okie_number || "N/A"}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            Project Type{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight">
            {project.project_type}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Customer>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Grid item fontWeight="fontWeightMedium">
              Remaining Balance{" "}
            </Grid>
            <Grid item fontWeight="fontWeightLight">
              {getFinancialInformation(project).fRemainingBalance}
            </Grid>
          </Grid>
          <Divider mb={2} />
        </Customer>
        <Admin>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Grid item fontWeight="fontWeightMedium">
              Total Cost{" "}
            </Grid>
            <Grid item fontWeight="fontWeightLight">
              {getFinancialInformation(project).fTotalCost}
            </Grid>
          </Grid>
          <Divider mb={2} />
          {project.equipment_install_date && (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Grid item fontWeight="fontWeightMedium">
                  Profit{" "}
                </Grid>
                <Grid item fontWeight="fontWeightLight">
                  {getFinancialInformation(project).fProfit}
                </Grid>
              </Grid>
              <Divider mb={2} />
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Grid item fontWeight="fontWeightMedium">
                  Profit Margin{" "}
                </Grid>
                <Grid item fontWeight="fontWeightLight">
                  {getFinancialInformation(project).profitMargin}%
                </Grid>
              </Grid>
              <Divider mb={2} />
            </>
          )}
        </Admin>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            Project Length{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight">
            {GetProjectLength(project)}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item fontWeight="fontWeightMedium">
            Start Date{" "}
          </Grid>
          <Grid item fontWeight="fontWeightLight">
            {moment.utc(project.start_date).format("MM-DD-YYYY")}
          </Grid>
        </Grid>
        <Divider mb={5} />
        {project.equipment_install_date && (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              mt={-3}
            >
              <Grid item fontWeight="fontWeightMedium">
                Equipment Install Date{" "}
              </Grid>
              <Grid item fontWeight="fontWeightLight">
                {moment
                  .utc(project.equipment_install_date)
                  .format("MM-DD-YYYY")}
              </Grid>
            </Grid>
            <Divider mb={5} />
          </>
        )}
        <Customer>
          <Grid item xs={12} lg={12} fontWeight="fontWeightMedium">
            <Button
              mr={4}
              variant="contained"
              color="primary"
              disabled={!project.project_url}
              onClick={() => handleUrlClick(project.project_url)}
            >
              Project
            </Button>
            <Button
              mr={4}
              variant="contained"
              color="info"
              disabled={!project.bid_url}
              onClick={() => handleUrlClick(project.bid_url)}
            >
              Bid
            </Button>
            <Button
              mr={4}
              variant="contained"
              color="primary"
              disabled={!project.contract_url}
              onClick={() => handleUrlClick(project.contract_url)}
            >
              Contract
            </Button>
          </Grid>
        </Customer>
      </CardContent>
    </Card>
  );
}

export default React.memo(ProjectInfo);
