import React, { createContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from "@mui/material";

const DialogContext = createContext(null);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState({
    title: "",
    content: "",
    onConfirm: null,
    onCancel: null,
    onConfirmText: "Confirm",
  });

  const showDialog = (options) => {
    setDialogOptions(options);
    setOpen(true);
  };

  const hideDialog = () => {
    setOpen(false);
  };

  return (
    <DialogContext.Provider value={{ showDialog, hideDialog }}>
      {children}
      <Dialog open={open} onClose={hideDialog} TransitionComponent={Transition}>
        <DialogTitle>{dialogOptions.title}</DialogTitle>
        <DialogContent>{dialogOptions.content}</DialogContent>
        <DialogActions>
          {dialogOptions.onCancel && (
            <Button onClick={dialogOptions.onCancel} color="primary">
              Cancel
            </Button>
          )}
          {dialogOptions.onConfirm && (
            <Button
              onClick={dialogOptions.onConfirm}
              color="primary"
              variant="contained"
            >
              {dialogOptions.onConfirmText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};

export { DialogContext, DialogProvider };
