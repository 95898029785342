import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {
  fetchInfo,
  createNavItems,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import { formatToDosData } from "../utils/projectUtilities";
import { drawProgressMapping } from "../constants";
import Contractor from "../components/guards/ContractorGuard";
import useAuth from "../hooks/useAuth";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const { user } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    const updateNavItems = async () => {
      if (!user) {
        return;
      }

      try {
        const rawProjects = await fetchInfo("/api/projects/");
        const rawTodos = await fetchInfo("/api/todo");

        // Filter projects and todos based on contractor_id for non-admin users
        const projects =
          user && user.type === parseInt(process.env.REACT_APP_ADMIN_KEY)
            ? rawProjects
            : rawProjects.filter(
                (project) =>
                  project.contractors &&
                  project.contractors.some(
                    (contractor) =>
                      contractor.contractor_id === user.contractor_id
                  )
              );

        const todos =
          user && user.type === parseInt(process.env.REACT_APP_ADMIN_KEY)
            ? rawTodos
            : rawTodos.filter(
                (todo) =>
                  !todo.contractor ||
                  (todo.contractor &&
                    todo.contractor.contractor_id === user.contractor_id)
              );

        // Badge for Under Construction Projects
        const underConstructionProjects = projects.filter(
          (project) => !project.equipment_install_date
        );

        // Badge for ToDos
        const formattedToDos = formatToDosData(todos);
        let unCompletedToDo = 0;
        let isCounted = false;
        formattedToDos.forEach((task) => {
          task.data.forEach((data) => {
            if (!data.completed && !isCounted) {
              unCompletedToDo++;
              isCounted = true;
            }
          });
          isCounted = false;
        });

        // Badge for Draws Due
        const getOutstandingDraws = (project) => {
          const outstandingDraws = [];

          if (!project.draws) {
            return null;
          }

          for (let draw of project.draws) {
            //Make sure a draw type was assigned
            if (draw.draw_type) {
              if (
                !draw.draw_paid &&
                drawProgressMapping[draw.draw_type].every((progress) =>
                  project.progress.includes(progress)
                )
              ) {
                outstandingDraws.push(draw);
              }
            }
          }

          return outstandingDraws;
        };

        let totalDrawsDue = 0;
        rawProjects.map((project) => {
          const outstandingDraws = getOutstandingDraws(project);
          totalDrawsDue += outstandingDraws.length;
        });

        // Set Badges By Type
        const badgeCounts = {
          projects:
            underConstructionProjects.length > 0
              ? underConstructionProjects.length
              : "",
          // Update other badge counts here (todo, invoice)
          todo: unCompletedToDo > 0 ? unCompletedToDo : "",
          invoice: "",
          draws: totalDrawsDue,
        };

        setNavItems(createNavItems(badgeCounts, user.type));
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    updateNavItems();
  }, [user]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Contractor>
        <Drawer>
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={navItems}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={navItems}
            />
          </Box>
        </Drawer>
      </Contractor>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />

        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
