import { progressOptions } from "../constants";
import { formatCurrency } from "./formating";
import crypto from "crypto";

import moment from "moment";

// Format Project data
export const formatProjectData = (projectData) => {
  // Check if the progress string is valid JSON
  if (projectData.progress && projectData.progress[0] === "[") {
    // Parse the JSON string into an array
    projectData.progress = JSON.parse(projectData.progress);
  } else {
    // Set progress to an empty array if it's not a valid JSON array string
    projectData.progress = [];
  }

  // Check if costs string is valid JSON
  if (projectData.costs && projectData.costs[0] === "[") {
    projectData.costs = JSON.parse(projectData.costs);

    // Sort Costs by date
    projectData.costs.sort((a, b) => {
      const dateA = new Date(a.date); // replace 'date' with the actual property name holding the date
      const dateB = new Date(b.date); // replace 'date' with the actual property name holding the date
      return dateA - dateB; // sort in ascending order
    });
  } else {
    projectData.costs = [];
  }

  // Format ToDo Data
  if (projectData.todos) {
    formatToDosData(projectData.todos);
  }

  // Format Contractor Data
  if (projectData.contractors) {
    formatContractorsData(projectData.contractors);
  }

  // Format start_date using Moment.js
  if (projectData.start_date) {
    projectData.start_date = moment
      .utc(projectData.start_date)
      .format("YYYY-MM-DD");
  }

  // Format equipment_install_date using Moment.js
  if (projectData.equipment_install_date) {
    projectData.equipment_install_date = moment
      .utc(projectData.equipment_install_date)
      .format("YYYY-MM-DD");
  }

  return projectData;
};

// Format Array of Project Data
export const formatProjectsData = (projects) => {
  return projects.map((project) => formatProjectData(project));
};

export const formatToDoData = (todoData) => {
  if (todoData.data && todoData.data[0] === "[") {
    todoData.data = JSON.parse(todoData.data);
  } else {
    todoData.data = [];
  }

  if (todoData.date) {
    todoData.date = moment.utc(todoData.date).format("YYYY-MM-DD");
  }

  return todoData;
};

export const formatToDosData = (todos) => {
  return todos.map((todo) => formatToDoData(todo));
};

export const formatContractorData = (contractorData) => {
  if (contractorData.types && contractorData.types[0] === "[") {
    contractorData.types = JSON.parse(contractorData.types);
  } else {
    contractorData.types = [];
  }

  if (contractorData.phone_number) {
    contractorData.phone_number = formatPhoneNumber(
      contractorData.phone_number
    );
  }

  return contractorData;
};

export const formatContractorsData = (contractors) => {
  return contractors.map((contractor) => formatContractorData(contractor));
};

function formatPhoneNumber(phoneNumber) {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export const getProgressOptions = (projectType) => {
  return progressOptions[projectType] || [];
};

export const getFinancialInformation = (project) => {
  let remainingBalance = 0;
  let totalCost = 0;

  if (project.draws) {
    project.draws.map((draw) => {
      if (!draw.draw_paid) {
        remainingBalance += parseFloat(draw.draw_amount);
      }
    });
  }
  if (project.costs) {
    project.costs.map((cost) => {
      totalCost += parseFloat(cost.amount);
    });
  }
  const drawsCollected = parseFloat(project.project_cost) - remainingBalance;
  const profit = drawsCollected - totalCost;
  const profitMargin = Math.round((profit / project.project_cost) * 100);
  const fRemainingBalance = formatCurrency(remainingBalance);
  const fProfit = formatCurrency(profit);
  const fTotalCost = formatCurrency(totalCost);
  const fDrawsCollected = formatCurrency(drawsCollected);

  return {
    totalCost,
    fTotalCost,
    drawsCollected,
    fDrawsCollected,
    profit,
    fProfit,
    remainingBalance,
    fRemainingBalance,
    profitMargin,
  };
};

export const getTotalFinancialInfo = (projects) => {
  let totalProjectCosts = 0;
  let totalDrawsCollected = 0;
  let totalCostsPaid = 0;
  let totalProfit = 0;
  let totalRemainingBalance = 0;

  projects.forEach((project) => {
    if (project.project_cost !== 0) {
      const financialInfo = getFinancialInformation(project);

      totalProjectCosts += parseFloat(project.project_cost);
      totalDrawsCollected += financialInfo.drawsCollected;
      totalCostsPaid += financialInfo.totalCost;
      totalProfit += financialInfo.profit;
      totalRemainingBalance += financialInfo.remainingBalance;
    }
  });

  const totalProfitMargin = Math.round((totalProfit / totalProjectCosts) * 100);
  const fTotalProjectCosts = formatCurrency(totalProjectCosts);
  const fTotalCostsPaid = formatCurrency(totalCostsPaid);
  const fTotalProfit = formatCurrency(totalProfit);
  const fTotalDrawsCollected = formatCurrency(totalDrawsCollected);
  const fTotalRemainingBalance = formatCurrency(totalRemainingBalance);

  return {
    totalProjectCosts,
    fTotalProjectCosts,
    totalDrawsCollected,
    fTotalDrawsCollected,
    totalCostsPaid,
    fTotalCostsPaid,
    totalProfit,
    fTotalProfit,
    totalProfitMargin,
    totalRemainingBalance,
    fTotalRemainingBalance,
  };
};

export async function initDb() {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open("MyDatabase", 1);

    openRequest.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("keys");
    };

    openRequest.onsuccess = (event) => {
      resolve(event.target.result);
    };

    openRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

export const encrypt = (text) => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY, "hex"),
    iv
  );

  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString("hex") + ":" + encrypted.toString("hex");
};

export const decrypt = (text) => {
  const textParts = text.split(":");
  const iv = Buffer.from(textParts.shift(), "hex");
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY, "hex"),
    iv
  );

  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
};
