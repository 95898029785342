/* eslint-disable prettier/prettier */
// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const drawType = [
  "Down Payment",
  "Shell",
  "Tile/Coping",
  "Deck",
  "Final",
  "Lyon - Dig",
  "Lyon - Shell",
  "Lyon - Deck",
  "Lyon - Final",
];

export const drawProgressMapping = {
  "Down Payment": ["Contract"],
  "Shell": ["Gunite"],
  "Tile/Coping": ["Tile"],
  "Deck": ["Deck"],
  "Final": ["Equipment"],
  "Lyon - Dig": ["Dig"],
  "Lyon - Shell": ["Gunite"],
  "Lyon - Deck": ["Deck"],
  "Lyon - Final": ["Equipment"],
};

export const plasterColor = [
  "White",
  "French Gray",
  "Medium Gray",
  "Tahoe Gray",
  "Lakeside Blue",
  "Laguna Blue",
  "Midnight Blue",
  "Turquoise",
  "Black",
];

export const costTypes = [
  "Setup Package",
  "Setup",
  "Shell",
  "Setup/Shell",
  "Cleanup",
  "Shotcrete",
  "Tile",
  "Coping",
  "Tile/Coping",
  "Deck",
  "Turf",
  "Gas",
  "Electrical",
  "Equipment",
  "Plaster",
  "Dirtwork",
  "Misc",
  "Cover",
];

export const contractorTypes = [
  "Dig",
  "Form",
  "Steel",
  "Plumb",
  "Shell",
  "Tile/Coping",
  "Rockwork",
  "Pavers",
  "Turf",
  "Concrete",
  "Cleanup",
  "Plaster",
  "Electrical",
  "Plumber",
  "Cover",
];

export const progressOptions = {
  "Concrete Pool": [
    "Design",
    "Contract",
    "Permit",
    "SCP Package",
    "Form Materials",
    "Dig",
    "Form",
    "Steel",
    "Plumb",
    "Grounding Inspection",
    "Gunite",
    "Tile",
    "Coping",
    "Deck Inspection",
    "Deck",
    "Fittings",
    "Plaster",
    "Equipment",
    "Salt",
    "Walkthrough",
  ],
  "Remodel Pool": [
    "Design",
    "Contract",
    "Permit",
    "Demo",
    "Tile",
    "Coping",
    "Deck",
    "Fittings",
    "Plaster",
    "Equipment",
    "Salt",
    "Walkthrough",
  ],
  "Vinyl Pool": [
    "Design",
    "Contract",
    "Permit",
    "SCP Package",
    "Dig",
    "Set Panels",
    "Pour Collar",
    "Plumb",
    "Grounding Inspection",
    "Back-Fill",
    "Coping",
    "Deck Inspection",
    "Deck",
    "Vermiculite",
    "Set Liner",
    "Equipment",
    "Walkthrough",
  ],
};
