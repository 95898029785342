import React from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  Typography,
  Button as MuiButton,
  Grid,
  Card,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

function handleUrlClick(path) {
  if (path) {
    window.open(path, "_blank");
  } else {
    console.log("URL is not available.");
  }
}

function GetSaltNeeded(totalGallons) {
  const salt = Math.floor(totalGallons * 8.35 * 0.0032);
  const bags = Math.ceil(salt / 40);

  return `${salt} lbs (${bags} bags)`;
}

function PoolInfo({ project }) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom mb={5}>
          Pool Info
        </Typography>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Pool Gallons{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.pool_gallons}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Spa Gallons{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.spa_gallons}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Pool Tile{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.pool_tile}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Step Tile{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.step_tile}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Pool Coping{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.pool_coping}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Spa Coping{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.spa_coping}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Wall Veneer{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.wall_veneer}
          </Grid>
        </Grid>
        <Divider mb={2} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
            Plaster Color{" "}
          </Grid>
          <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
            {project.pool_info.plaster_color}
          </Grid>
        </Grid>
        <Divider mb={2} />
        {project.pool_info.salt_pool && (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Grid item xs={6} lg={3} fontWeight="fontWeightMedium">
                Salt Needed{" "}
              </Grid>
              <Grid item xs={6} lg={3} fontWeight="fontWeightLight">
                {GetSaltNeeded(
                  project.pool_info.pool_gallons + project.pool_info.spa_gallons
                )}
              </Grid>
            </Grid>
            <Divider mb={5} />
          </>
        )}

        <Grid item xs={12} lg={12} fontWeight="fontWeightMedium">
          <Button
            mr={4}
            variant="contained"
            color="primary"
            disabled={!project.layout_url}
            onClick={() => handleUrlClick(project.layout_url)}
          >
            Layout Plans
          </Button>
          <Button
            mr={4}
            variant="contained"
            color="secondary"
            disabled={!project.floor_system_layout_url}
            onClick={() => handleUrlClick(project.floor_system_layout_url)}
          >
            Floor System Plans
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(PoolInfo);
