import React from "react";
import useAuth from "../../hooks/useAuth";

function AdminGuard({ children }) {
  const { user } = useAuth();

  if (user && user.type === parseInt(process.env.REACT_APP_ADMIN_KEY)) {
    return <>{children}</>;
  }

  return null;
}

export default AdminGuard;
