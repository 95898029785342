import React from "react";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

function ContractorGuard({ children }) {
  const { isAuthenticated, loggedInUser, user } = useAuth();

  if (!user) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (
    (user && user.type === parseInt(process.env.REACT_APP_CONTRACTOR_KEY)) ||
    user.type === parseInt(process.env.REACT_APP_ADMIN_KEY)
  ) {
    return <>{children}</>;
  }

  return null;
}

export default ContractorGuard;
