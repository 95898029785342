import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "../../../utils/axios";
import {
  formatProjectsData,
  formatToDoData,
} from "../../../utils/projectUtilities";
import SnackbarContext from "../../../contexts/SnackbarContext";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import useDialog from "../../../hooks/useDialog";
import useAuth from "../../../hooks/useAuth";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  Box,
  CircularProgress,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Check } from "react-feather";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Centered = styled.div`
  text-align: center;
`;

function EditableCell({ value, onChange, onKeyDown }) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const handleSave = (event) => {
    if (event.key === "Enter" || event.type === "blur") {
      setEditing(false);
      onChange(event);
    }
  };

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  return editing ? (
    <TextField
      inputRef={inputRef}
      defaultValue={value}
      fullWidth
      onBlur={handleSave}
      onKeyDown={handleSave}
    />
  ) : (
    <div
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => setEditing(true)}
    >
      {value}
    </div>
  );
}

function ToDoControls({
  todo,
  updateToDo,
  addTask,
  removeTask,
  saveChanges,
  changesMade,
  selectedRows,
  setSelectedRows,
  setOpenDialog,
  setChangesMade,
  setUpdatedData,
  projects,
  contractors,
  setSelectedProjectId,
  selectedProjectId,
  setSelectedContractorId,
  selectedContractorId,
}) {
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleEditCellChange = (params, event) => {
    const { id, field, value } = params;
    setUpdatedData((prevData) => {
      return prevData.map((item, index) =>
        index === id ? { ...item, [field]: value } : item
      );
    });

    setChangesMade(true);
  };

  const handleSelectProjectIdChange = (value) => {
    setSelectedProjectId(value);
    setChangesMade(true);
  };

  const handleSelectContractorIdChange = (value) => {
    setSelectedContractorId(value);
    setChangesMade(true);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" mb={6}>
        {changesMade ? (
          <Grid item mr={2}>
            <Button
              mr={2}
              variant="contained"
              color="primary"
              onClick={saveChanges}
            >
              Save
            </Button>
          </Grid>
        ) : (
          <div />
        )}
        <Grid item mr={2}>
          <Button
            p={2}
            variant="outlined"
            color="error"
            onClick={() => handleOpenDialog()}
          >
            Delete To-Do
          </Button>
        </Grid>
      </Grid>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            <Grid container direction="row" mb={6}>
              <Grid item p={2} ml={-2} md={2} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="project-select-label">Project</InputLabel>
                  <Select
                    labelId="project-select-label"
                    value={selectedProjectId}
                    onChange={(event) =>
                      handleSelectProjectIdChange(event.target.value)
                    }
                    label="Select Project"
                  >
                    {[...projects]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((project) => (
                        <MenuItem
                          key={project.project_id}
                          value={project.project_id}
                        >
                          {project.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item p={2} ml={-2} md={2} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="contractor-select-label">
                    Contractor
                  </InputLabel>
                  <Select
                    labelId="contractor-select-label"
                    value={selectedContractorId}
                    onChange={(event) =>
                      handleSelectContractorIdChange(event.target.value)
                    }
                    label="Select Contractor"
                  >
                    {contractors.map((contractor) => (
                      <MenuItem
                        key={contractor.contractor_id}
                        value={contractor.contractor_id}
                      >
                        {contractor.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Typography>

          <Grid container p={2}>
            <Grid item mr={2}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                size="small"
                onClick={addTask}
              >
                Add Task
              </Button>
            </Grid>
            <Grid item mr={2}>
              {selectedRows.length > 0 ? (
                <Button
                  mr={2}
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={removeTask}
                >
                  Remove Tasks
                </Button>
              ) : (
                <div />
              )}
            </Grid>
          </Grid>
          <DataGrid
            rows={todo.data.map((item, index) => ({ ...item, id: index }))}
            columns={[
              {
                field: "checkbox",
                width: 50,
                headerAlign: "center",
                headerName: "",
                renderHeader: () => (
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < todo.data.length
                    }
                    checked={
                      todo.data.length > 0 &&
                      selectedRows.length === todo.data.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedRows(todo.data.map((item, index) => index));
                      } else {
                        setSelectedRows([]);
                      }
                    }}
                  />
                ),
                renderCell: (params) => (
                  <Checkbox
                    color="primary"
                    checked={selectedRows.includes(params.row.id)}
                    onChange={() => {
                      const selection = [...selectedRows];
                      const index = selection.indexOf(params.row.id);
                      if (index === -1) {
                        selection.push(params.row.id);
                      } else {
                        selection.splice(index, 1);
                      }
                      setSelectedRows(selection);
                    }}
                  />
                ),
              },
              {
                field: "task",
                headerName: "Task",
                width: isMobile ? 180 : undefined,
                flex: isMobile ? undefined : 1,
                editable: true,
                renderCell: (params) => (
                  <EditableCell
                    value={params.row.task}
                    onChange={(event) => handleEditCellChange(params, event)}
                    onKeyDown={(event) => handleEditCellChange(params, event)}
                  />
                ),
              },
              {
                field: "completed",
                headerName: "Completed",
                width: 150,
                renderCell: (params) =>
                  params.value ? (
                    <Check />
                  ) : (
                    <Checkbox
                      checked={params.value}
                      onChange={(event) =>
                        updateToDo(params.id, "completed", event.target.checked)
                      }
                    />
                  ),
              },
              { field: "completed_by", headerName: "Completed By", width: 200 },
              {
                field: "date_completed",
                headerName: "Completed On",
                width: 200,
                valueFormatter: (params) =>
                  params.value
                    ? moment.utc(params.value).format("MM-DD-YYYY")
                    : "",
              },
            ]}
            autoHeight
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            hideFooter
            onSelectionModelChange={(newSelectionModel) =>
              setSelectedRows(newSelectionModel.map(Number))
            }
            onCellEditCommit={handleEditCellChange}
          />
        </CardContent>
      </Card>
    </>
  );
}

function ToDoDetails() {
  const { todo_id } = useParams();
  const [toDo, setToDo] = useState(null);
  const [projects, setProjects] = useState(null);
  const [contractors, setContractors] = useState(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const [changesMade, setChangesMade] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedContractorId, setSelectedContractorId] = useState("");
  const { showDialog, hideDialog } = useDialog();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleDeleteProject = useCallback(() => {
    showDialog({
      title: "Delete To-Do",
      content: (
        <>
          Are you sure you want to delete this To-Do. All information will be
          gone forever if you continue.
        </>
      ),
      onConfirm: async () => {
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_SERVER_HOST}/api/todo/${todo_id}`
          );
          console.log("To-Do Deleted", response.data);
          showSnackbar("To-Do Deleted", "success");
          navigate("/projects/todo/");
        } catch (error) {
          console.error("Error deleting To-Do:", error);
          showSnackbar("Failed to Delete To-Do", "error");
        }
        hideDialog();
      },
      onCancel: () => {
        console.log("Canceled");
        hideDialog();
      },
      onConfirmText: "Delete",
    });
  }, [showDialog, hideDialog, todo_id, showSnackbar, navigate]);

  const updateToDo = async (index, field, value) => {
    // Update the todo object's data based on the index, field, and value
    const updatedData = toDo.data.map((item, i) => {
      if (i === index) {
        if (field === "completed") {
          return {
            ...item,
            completed: value,
            date_completed: value ? new Date().toISOString() : null,
            completed_by: value
              ? user?.first_name + " " + user?.last_name
              : null, // Replace this with the actual member_id from the login token
          };
        } else {
          return { ...item, [field]: value };
        }
      }
      return item;
    });

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_HOST}/api/todo/${todo_id}`,
        { data: JSON.stringify(updatedData) }
      );

      // Update the todo state with the new data
      setToDo((prevToDo) => ({ ...prevToDo, data: updatedData }));

      showSnackbar("Task updated successfully!", "success");
    } catch (error) {
      console.error("Error updating todo:", error);
      showSnackbar("Failed to Update Task", "error");
    }
  };

  const addTask = () => {
    const newTask = {
      task: "New Task",
      completed: false,
      completed_by: null,
      date_completed: null,
    };
    setUpdatedData([...updatedData, newTask]);
    setChangesMade(true);
  };

  const removeTask = () => {
    if (selectedRows.length > 0) {
      setUpdatedData(updatedData.filter((_, i) => !selectedRows.includes(i)));
      setSelectedRows([]);
    } else {
      setUpdatedData(updatedData.slice(0, -1));
    }
    setChangesMade(true);
  };

  const saveChanges = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_HOST}/api/todo/${todo_id}`,
        {
          data: JSON.stringify(updatedData),
          project_id: selectedProjectId,
          contractor_id: selectedContractorId,
        }
      );

      setToDo((prevToDo) => ({
        ...prevToDo,
        data: updatedData,
        project_id: selectedProjectId,
        contractor_id: selectedContractorId,
      }));
      setChangesMade(false);
      showSnackbar("Changes saved successfully!", "success");
    } catch (error) {
      console.error("Error saving changes:", error);
      showSnackbar("Failed to save changes", "error");
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_HOST}/api/todo/${todo_id}`
        );

        const projectResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_HOST}/api/projects/`
        );

        const contractorResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_HOST}/api/contractors/`
        );

        const formattedToDo = formatToDoData(response.data);
        const formattedProjects = formatProjectsData(projectResponse.data);

        setToDo(formattedToDo);
        setProjects(formattedProjects);
        setContractors(contractorResponse.data);
      } catch (error) {
        console.error("Error fetching:", error);
        showSnackbar("Failed to Load Data", "error");
      }
    };

    fetchProject();
  }, [showSnackbar, todo_id]);

  useEffect(() => {
    if (toDo) {
      setUpdatedData(toDo.data);
      setSelectedProjectId(toDo.project_id);
      setSelectedContractorId(toDo.contractor_id);
    }
  }, [toDo]);

  if (!toDo) {
    return (
      <Card mb={6}>
        <CardContent>
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
          <Typography variant="h6" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="To-Do Details" />
      <Typography variant="h3" gutterBottom display="inline">
        {toDo.title} Details
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/projects/todo">
          To-Do
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ToDoControls
            todo={{ ...toDo, data: updatedData }}
            updateToDo={updateToDo}
            addTask={addTask}
            removeTask={removeTask}
            saveChanges={saveChanges}
            changesMade={changesMade}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setOpenDialog={handleDeleteProject}
            setChangesMade={setChangesMade}
            setUpdatedData={setUpdatedData}
            projects={projects}
            contractors={contractors}
            setSelectedProjectId={setSelectedProjectId}
            selectedProjectId={selectedProjectId}
            setSelectedContractorId={setSelectedContractorId}
            selectedContractorId={selectedContractorId}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ToDoDetails;
