export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const userTypeKey = {
  userAdmin: parseInt(process.env.REACT_APP_ADMIN_KEY),
  userContractor: parseInt(process.env.REACT_APP_CONTRACTOR_KEY),
  userCustomer: parseInt(process.env.REACT_APP_CUSTOMER_KEY),
};

export const dropboxConfig = {
  appKey: process.env.REACT_APP_DROPBOX_APP_KEY,
  appSecret: process.env.REACT_APP_DROPBOX_APP_SECRET,
  refreshToken: process.env.REACT_APP_DROPBOX_REFESH_TOKEN,
};
