import React, { useState } from "react";
import styled from "@emotion/styled";
import axios from "../../../utils/axios";
import moment from "moment";
import Admin from "../../../components/guards/AdminGuard";
import {
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Chip = styled(MuiChip)(spacing);

const Typography = styled(MuiTypography)(spacing);

function ProgressPictures({ project }) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Progress Pictures
        </Typography>
        <Divider mb={8} mt={4} />
        Slideshow of pictures
      </CardContent>
    </Card>
  );
}

export default React.memo(ProgressPictures);
