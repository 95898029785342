import React from "react";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

function CustomerGuard({ children }) {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (
    (user && user.type === parseInt(process.env.REACT_APP_CUSTOMER_KEY)) ||
    user.type === parseInt(process.env.REACT_APP_ADMIN_KEY)
  ) {
    return <>{children}</>;
  }

  return null;
}

export default CustomerGuard;
