import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import ContractorGuard from "./components/guards/ContractorGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";

// Project Pages
import Details from "./pages/projects/Details/Index";

// Todo Detail Page
import ToDoDetails from "./pages/projects/ToDo/Details";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

// Projects
const Projects = async(() => import("./pages/projects/Projects"));
const AddNewProject = async(() => import("./pages/projects/Add"));
const ProjectTODO = async(() => import("./pages/projects/ToDo/ToDo"));
const EditProject = async(() => import("./pages/projects/Edit/Edit"));

// Draws
const Draws = async(() => import("./pages/draws/index"));

//Calendar
const Calendar = async(() => import("./pages/calendar/Calendar"));

// Invoices
const Invoices = async(() => import("./pages/invoices/Invoices"));

// Contractors
const Contractors = async(() => import("./pages/contractors/Contractors"));
const AddContractor = async(() => import("./pages/contractors/Add"));
const EditContractor = async(() => import("./pages/contractors/Edit"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "projects",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <ContractorGuard>
            <Projects />
          </ContractorGuard>
        ),
      },
      {
        path: "add",
        element: (
          <AdminGuard>
            <AddNewProject />
          </AdminGuard>
        ),
      },
      {
        path: "todo",
        element: (
          <ContractorGuard>
            <ProjectTODO />
          </ContractorGuard>
        ),
      },
      {
        path: "todo/:todo_id",
        element: (
          <ContractorGuard>
            <ToDoDetails />
          </ContractorGuard>
        ),
      },
      {
        path: ":project_id",
        element: <Details />,
      },
      {
        path: "edit/:project_id",
        element: (
          <AdminGuard>
            <EditProject />
          </AdminGuard>
        ),
      },
    ],
  },
  {
    path: "draws",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Draws />,
      },
    ],
  },
  {
    path: "invoices",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Invoices />,
      },
    ],
  },
  {
    path: "calendar",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "contractors",
    element: (
      <AuthGuard>
        <ContractorGuard>
          <DashboardLayout />
        </ContractorGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Contractors />,
      },
      {
        path: "add",
        element: (
          <AdminGuard>
            <AddContractor />
          </AdminGuard>
        ),
      },
      {
        path: ":contractor_id",
        element: (
          <AdminGuard>
            <EditContractor />
          </AdminGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
];

export default routes;
