import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import axios from "../../../utils/axios";
import moment from "moment";
import * as Yup from "yup";
import { formatCurrency } from "../../../utils/formating";
import { costTypes } from "../../../constants";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  Grid as MuiRow,
  Typography as MuiTypography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Slide,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";

const Card = styled(MuiCard)(spacing);

const GridRow = styled(MuiRow)(({ theme }) => ({
  ...spacing,
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Typography = styled(MuiTypography)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CostDialog = ({ open, onClose, onAddCost }) => {
  const initialValues = {
    amount: "",
    costType: "",
    date: new Date(),
  };

  const validationSchema = Yup.object({
    amount: Yup.number().required("Amount is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    onAddCost(values);
    onClose();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Add a cost</DialogTitle>
            <DialogContent>
              <TextField
                name="amount"
                label="Amount"
                type="number"
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type-label"
                  name="costType"
                  value={values.costType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Type"
                  error={touched.costType && !!errors.costType}
                >
                  {costTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <MobileDatePicker
                name="date"
                label="Paid date"
                value={values.date}
                variant="outlined"
                onChange={(value) => setFieldValue("date", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

function CostInfo({ project, fetchProject }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [costs, setCosts] = useState([]);

  const handleAddCost = (newCost) => {
    const val = [...costs, newCost];
    updateCost(val);
  };

  const updateCost = async (newCosts) => {
    const projectData = {
      ...project,
      costs: newCosts,
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_HOST}/api/projects/${project.project_id}`,
        projectData
      );
      fetchProject();
    } catch (error) {
      console.error("Error updating costs:", error);
    }
  };

  useEffect(() => {
    if (project.costs) {
      setCosts(project.costs);
    }
  }, [project]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom mb={5}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item fontWeight="fontWeightMedium">
              Cost
            </Grid>
            <Grid item fontWeight="fontWeightMedium">
              <Button
                mr={2}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setDialogOpen(true)}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Typography>

        <Grid container>
          <GridRow container direction="row">
            <Grid item xs={4}>
              <Typography variant="subtitle1">Amount</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Typography variant="subtitle1">Type</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Typography variant="subtitle1">Date Paid</Typography>
            </Grid>
          </GridRow>

          {costs.map((cost, index) => (
            <GridRow container key={index} alignItems="center">
              <Grid item xs={4}>
                {formatCurrency(cost.amount)}
              </Grid>

              <Grid item xs={4} textAlign="center">
                {cost.costType}
              </Grid>
              <Grid item xs={4} textAlign="center">
                {moment(cost.date).format("MM-DD-YYYY")}
              </Grid>
            </GridRow>
          ))}
        </Grid>
        <CostDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onAddCost={handleAddCost}
        />
      </CardContent>
    </Card>
  );
}

export default React.memo(CostInfo);
