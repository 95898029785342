import React, { useCallback, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { getProgressOptions } from "../../../utils/projectUtilities";
import axios from "../../../utils/axios";
import useDialog from "../../../hooks/useDialog";
import SnackbarContext from "../../../contexts/SnackbarContext";

const ProgressCard = ({ project, fetchProject }) => {
  const { showDialog, hideDialog } = useDialog();
  const { showSnackbar } = useContext(SnackbarContext);

  const progress = project.progress;
  const projectType = project.project_type;
  const progressOptions = getProgressOptions(projectType);
  const currentProgressIndex = progress.length;
  const nextStageIndex = currentProgressIndex + 1;

  let startDisplayIndex;
  let endDisplayIndex;
  let activeStep;

  if (progress.length === 1) {
    startDisplayIndex = 0;
    endDisplayIndex = Math.min(progressOptions.length, nextStageIndex + 1);
    activeStep = 1;
  } else if (progress.length === progressOptions.length) {
    startDisplayIndex = Math.max(0, currentProgressIndex - 2);
    endDisplayIndex = progressOptions.length;
    activeStep = 3;
  } else {
    startDisplayIndex = Math.max(0, currentProgressIndex - 1);
    endDisplayIndex = Math.min(progressOptions.length, nextStageIndex + 1);
    activeStep = 1;
  }

  const displayedProgress = progressOptions.slice(
    startDisplayIndex,
    endDisplayIndex
  );

  const handleUpdateProgress = useCallback(
    (step, index) => {
      showDialog({
        title: "Update Progress",
        content: (
          <>
            Would you like to mark{" "}
            <Typography component="span" fontWeight="bold" fontSize={16}>
              {step}
            </Typography>{" "}
            as complete.
          </>
        ),
        onConfirm: async () => {
          try {
            const projectData = {
              ...project,
              progress: [...project.progress, step],
            };
            await axios.put(
              `${process.env.REACT_APP_SERVER_HOST}/api/projects/${project.project_id}`,
              projectData
            );

            showSnackbar("Progress Updated", "success");
            fetchProject();
          } catch (error) {
            console.error("Error updating progress:", error);
            showSnackbar("Failed to updating progress", "error");
          }
          hideDialog();
        },
        onCancel: () => {
          console.log("Canceled");
          hideDialog();
        },
        onConfirmText: "Completed",
      });
    },
    [showDialog, hideDialog, project, showSnackbar, fetchProject]
  );

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Current Stage
        </Typography>
        <Box width="100%">
          <Stepper activeStep={activeStep} alternativeLabel>
            {displayedProgress.map((stage, index) => (
              <Step
                key={index}
                onClick={() => {
                  if (startDisplayIndex + index === currentProgressIndex) {
                    handleUpdateProgress(stage, startDisplayIndex + index);
                  }
                }}
              >
                <StepLabel
                  icon={startDisplayIndex + index + 1}
                  sx={{
                    cursor:
                      startDisplayIndex + index === currentProgressIndex
                        ? "pointer"
                        : "default",
                  }}
                >
                  {stage}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </CardContent>
    </Card>
  );
};

export default React.memo(ProgressCard);
