import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import axios from "../../../utils/axios";
import moment from "moment";
import Admin from "../../../components/guards/AdminGuard";
import { formatCurrency } from "../../../utils/formating";
import {
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Grid,
  Grid as MuiRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers";

const Card = styled(MuiCard)(spacing);

const GridRow = styled(MuiRow)(({ theme }) => ({
  ...spacing,
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Chip = styled(MuiChip)(spacing);

const Typography = styled(MuiTypography)(spacing);

function CostInfo({ project, fetchProject }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(new Date());
  const [selectedDrawId, setSelectedDrawId] = useState(null);

  const handleOpen = (drawId) => {
    setSelectedDrawId(drawId);
    const newDate = new Date();
    setValue(newDate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateDraw = async (draw_id, date) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_HOST}/api/projects/payDraw/${draw_id}/`,
        {
          date: date.toISOString(), // Convert the date to an ISO string
        }
      );
      fetchProject();
      console.log("Draw paid successfully:", response.data);
    } catch (error) {
      console.error("Error updating draw:", error);
    }
  };

  const handleDateSelected = (selectedDate, draw_id) => {
    console.log("Selected date:", selectedDate, "Draw ID:", draw_id);
    updateDraw(draw_id, selectedDate);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Draws
        </Typography>

        <Grid container>
          <GridRow container direction="row">
            <Grid item xs={3}>
              <Typography variant="subtitle1">Amount</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">Type</Typography>
            </Grid>
            <Admin>
              <Grid item xs={3} textAlign="center">
                <Typography variant="subtitle1">Status</Typography>
              </Grid>
            </Admin>
            <Grid item xs={3} textAlign="center">
              <Typography variant="subtitle1">Date Paid</Typography>
            </Grid>
          </GridRow>

          {project.draws.map((draw, index) => (
            <GridRow container key={index} alignItems="center">
              <Grid item xs={3}>
                {formatCurrency(draw.draw_amount)}
              </Grid>
              <Grid item xs={3}>
                {draw.draw_type}
              </Grid>
              <Admin>
                <Grid item xs={3} textAlign="center">
                  {draw.draw_paid ? (
                    <Chip
                      size="small"
                      mr={1}
                      mb={1}
                      label="PAID"
                      color="success"
                    />
                  ) : (
                    <Chip
                      onClick={() => handleOpen(draw.draw_id)}
                      size="small"
                      mr={1}
                      mb={1}
                      label="DUE"
                      color="error"
                    />
                  )}

                  <MobileDatePicker
                    open={open}
                    onClose={handleClose}
                    onAccept={() => handleDateSelected(value, selectedDrawId)}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    renderInput={() => null}
                    DialogProps={{
                      fullWidth: true,
                      maxWidth: "sm",
                    }}
                  />
                </Grid>
              </Admin>
              <Grid item xs={3} textAlign="center">
                {draw.date_paid
                  ? moment(draw.date_paid).format("MM-DD-YYYY")
                  : ""}
              </Grid>
            </GridRow>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(CostInfo);
